import { getDomainTitle } from '@/utils/get-domain-title';
const domainTitle = getDomainTitle();

export default [
  {
    path: '/documents',
    meta: {
      protected: true,
      adminOnly: true
    },
    component: () => import('@/components/documents'),
    children: [
      {
        path: '/',
        name: 'TasksList',
        component: () => import('@/components/documents/tasks-list.vue'),
        meta: {
          title: `Documents - ${domainTitle}`
        }
      }
    ]
  }
];
