import { render, staticRenderFns } from "./App.vue?vue&type=template&id=355db542"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_@babel+core@7.22.15__css-loader@6.8.1_webpack@5._k3b65icoqlaeakidu6dhxd76mi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports