import { render, staticRenderFns } from "./global-notification.vue?vue&type=template&id=1cad7558"
import script from "./global-notification.vue?vue&type=script&lang=js"
export * from "./global-notification.vue?vue&type=script&lang=js"
import style0 from "./global-notification.vue?vue&type=style&index=0&id=1cad7558&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_@babel+core@7.22.15__css-loader@6.8.1_webpack@5._k3b65icoqlaeakidu6dhxd76mi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports